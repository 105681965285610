import Image from "next/image";
import Link from "next/link";
import React from "react";

const ErrorPage = () => {
  return (
    <>
      <section className="sec-padd text-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="img-wrap image-container">
                <Image
                  fill
                  alt="page not found"
                  className="project-img image"
                  src="/images/404.png"
                />
              </div>
            </div>
            <div className="mt-4">
              <Link className="btn" href="/">
                GO TO HOME
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ErrorPage;
