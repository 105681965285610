import { cleanImage } from "@/services/imageHandling";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Container } from "react-bootstrap";

const TypologyProjects = ({ project }) => {
  // console.log("project", project);
  return (
    <section className="sec-padd-top typologies">
      <Container>
        {project &&
          project.map((projitem, index) => {
            return (
              <div className="project-wrap my-5" key={`project_${index}`}>
                <div className="row align-items-center">
                  <div className="col-md-4">
                    <div
                      className="project-image-wrapper"
                      data-aos="fade-right"
                      data-aos-delay="200"
                      data-aos-duration="1000"
                    >
                      <div className="project-image">
                        <div className="image-wrap position-reltive">
                          <Image
                            fill
                            alt={
                              projitem?.attributes?.project_image?.data
                                ?.attributes?.url?.alternativeText
                                ? projitem?.attributes?.project_image?.data
                                    ?.attributes?.url?.alternativeText
                                : projitem?.attributes?.Project_Title
                            }
                            className="image"
                            src={cleanImage(
                              projitem?.attributes?.project_image?.data
                                ?.attributes?.url
                            )}
                          />
                        </div>
                        <h3 className="proj-title">
                          {projitem?.attributes?.Project_Title}
                        </h3>
                        <div className="bord-effect"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div
                      className="project-content"
                      data-aos="fade-left"
                      data-aos-delay="200"
                      data-aos-duration="1000"
                    >
                      <p className="proj-loc">
                        <i className="fas fa-map-marker-alt"></i>{" "}
                        <span>{projitem?.attributes?.location}</span>
                        <br />
                        <i className="fas fa-bed"></i>{" "}
                        <span>{projitem?.attributes?.Configuration}</span>
                      </p>
                      <p className="carpet-area border-tb">
                        {projitem?.attributes?.Carpet_Area} (Carpet Area)
                      </p>
                      <div className="desc_text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              projitem?.attributes?.Project_Description.substring(
                                0,
                                250
                              ) + " ...",
                          }}
                        ></div>
                      </div>
                      <div className="btn-wrapper">
                        <div className="d-flex">
                          {/* <div className="butn-dark">
                            <a
                              className="red-btn"
                              href="images/floorplan/1.webp"
                              data-fancybox="floor"
                              rel="noopener"
                            >
                              Floor Plan
                            </a>
                          </div> */}
                          <div className="butn-dark">
                            <Link
                              className={
                                projitem?.attributes?.Download_brochure?.data
                                  ? "flat-btn btn d-flex"
                                  : "d-none"
                              }
                              href={cleanImage(
                                projitem?.attributes?.Download_brochure?.data
                                  ?.attributes?.url
                              )}
                              target="_blank"
                              rel="noopener"
                            >
                              Download Brochure
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="read-more-wrap mt-30 text-right">
                        <div className="butn-dark">
                          <Link
                            href={
                              projitem?.attributes?.slug
                                ? projitem?.attributes?.slug
                                : "#"
                            }
                            className="flat-btn btn"
                          >
                            View Details
                          </Link>
                        </div>
                        <div className="bl-effect"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Container>
    </section>
  );
};

export default TypologyProjects;
