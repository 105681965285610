import React, { useState } from "react";
import axios from "axios";
import Seo from "@/components/seo/Seo";
import qs from "qs";

import PageBanner from "@/components/Banner/Banner";
import TypologyProjects from "@/components/typology/TypologyProjects";
import { Container } from "react-bootstrap";
import Faqs from "@/components/typology/Faqs";
import ErrorPage from "@/components/404/ErrorPage";
const Typology = ({ data }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  const moreText = isReadMore
    ? data[0]?.attributes?.Description.slice(0, 800) + "..."
    : data[0]?.attributes?.Description;
  // console.log("data", data);
  const banner = data[0]?.attributes?.Banner;
  const seo = data[0]?.attributes?.seo;
  const project = data[0]?.attributes?.projects?.data;
  const faq = data[0]?.attributes?.Faq;

  return (
    <>
      {data.length !== 0 && data !== null ? (
        <>
          <Seo seo={seo} />
          <PageBanner banner={banner} />
          <TypologyProjects project={project} />
          <section
            className="sec-padd pt-0 description"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            <Container>
              <div className="desc_text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: moreText,
                  }}
                ></div>
                <div
                  onClick={toggleReadMore}
                  className={
                    moreText.length < 800 ? "d-none" : "d-block readmorebtn"
                  }
                >
                  {isReadMore ? (
                    <div className="flat-btn btn">
                      <span>Read More </span>
                    </div>
                  ) : (
                    <div className="flat-btn btn">
                      <span>Read Less </span>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </section>
          <Faqs faq={faq} />
        </>
      ) : (
        <ErrorPage />
      )}
    </>
  );
};

export default Typology;
/** For server side rendering */
export const getServerSideProps = async (context) => {
  // console.log(context.query.typology);
  try {
    const query = {
      filters: {
        slug: {
          $eq: context.query.typology,
        },
      },
      populate: [
        "*",
        "seo",
        "seo.metaImage",
        "seo.schema",
        "Banner.DesktopBanner",
        "Banner.MobileBanner",
        "projects",
        "projects.project_image",
        "Faq",
        "projects.Download_brochure",
      ],
    };

    /** If we are loading projects from anywhere other than the home page, then we load the configuration information also. */

    const queryString = qs.stringify(query, {
      encodeValuesOnly: true,
    });

    const endpoint = `${process.env.NEXT_PUBLIC_BACKEND_API_URL}/api/typologies?${queryString}`;
    // console.log(`Final url: ${endpoint}`);

    const response = await axios.get(endpoint);
    const data = response.data.data;
    // Pass data to the page via props
    return {
      props: { data },
    };
  } catch (error) {
    console.log("Error", error);
  }
};
