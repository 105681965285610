import React from "react";
import { Accordion, Container } from "react-bootstrap";

const Faqs = ({ faq }) => {
  return (
    <section className={faq.length ? "mt-4 sec-padd-bot" : "d-none"}>
      <Container>
        <div className="head_wrap text-center center-title">
          <h2 className="section-title mb-0">FAQ's</h2>
        </div>
        <Accordion className="location_wrapper mt-3">
          {faq &&
            faq.map((faqitem, index) => {
              return (
                <Accordion.Item key={`fas_${index}`} eventKey={faqitem.id}>
                  <Accordion.Header>{faqitem.Question}</Accordion.Header>
                  <Accordion.Body
                    dangerouslySetInnerHTML={{
                      __html: faqitem.Answer,
                    }}
                  ></Accordion.Body>
                </Accordion.Item>
              );
            })}
        </Accordion>
      </Container>
    </section>
  );
};

export default Faqs;
