import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

const PageBanner = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const webbanner = props?.banner?.DesktopBanner?.data?.attributes?.url;
  const mobbanner = props?.banner?.MobileBanner?.data?.attributes?.url;
  const bannerTitle = props?.banner?.BannerTitle;

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768); // Mobile view condition
      // console.log(setIsMobile(window.innerWidth < 768), 'stismobile');
    };

    checkScreenSize(); // Run on mount
    window.addEventListener("resize", checkScreenSize); // Listen for resize

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <>
      {/* Desktop Banner - Only Rendered if NOT Mobile */}
      {!isMobile && (
        <section
          className="inner-banner"
          style={{
            backgroundImage: `url(${process.env.NEXT_PUBLIC_BACKEND_API_URL + webbanner})`,
          }}
        >
          <div className="inner-overlay">
            <Container>
              <h1 className="banner-title text-white">{bannerTitle}</h1>
            </Container>
          </div>
        </section>
      )}

      {/* Mobile Banner - Only Rendered if Mobile */}
      {isMobile && (
        <section
          className="inner-banner"
          style={{
            backgroundImage: `url(${process.env.NEXT_PUBLIC_BACKEND_API_URL + mobbanner})`,
          }}
        >
          <div className="inner-overlay">
            <Container>
              <h1 className="banner-title text-white text-center">{bannerTitle}</h1>
            </Container>
          </div>
        </section>
      )}
    </>
  );
};

export default PageBanner;
